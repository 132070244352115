<template>
  <div class="header">
    <el-row>
      <el-col :span="12">
        <div class="logo_img" @click="logoClick">
          <div>
            <div class="header-logo">
              <img id="header-img" src="../../assets/logo.png" />
            </div>
          </div>
          <div style="height: 100px; line-height: 100px">
            <div class="company_name">杭州妆点信息技术有限公司</div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div id="menu_index">
          <!-- web端菜单导航  3AAEFD -->
          <div style="position: relative">
            <el-menu
              v-if="seen"
              :default-active="activeIndex"
              class="el-menu-demo"
              text-color="#000"
              style="height: 100px; border: 0; background-color: #ffffff"
              mode="horizontal"
              @select="handleSelect"
              active-text-color="#000"
            >
              <el-menu-item index="1" class="header-title">首页</el-menu-item>
              <el-sub-menu index="2">
                <template #title>
                  <div style="font-size: 18px">定制方案</div>
                </template>
                <el-menu-item index="2-1">高端定制网站解决方案</el-menu-item>
                <el-menu-item index="2-2">全渠道电商营销解决方案</el-menu-item>
                <el-menu-item index="2-3">小程序创新定制解决方案</el-menu-item>
              </el-sub-menu>
              <el-menu-item index="5" class="header-title"
                >关于我们</el-menu-item
              >
            </el-menu>
            <div
              style="
                position: absolute;
                right: -100px;
                top: 50%;
                margin-top: -16px;
              "
            ></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <div class="header2">
    <el-row>
      <el-col :span="10">
        <div id="menu_index">
          <!-- web端菜单导航  3AAEFD -->
          <div style="position: relative">
            <el-menu
              v-if="seen"
              :default-active="activeIndex"
              class="el-menu-demo"
              menu-trigger="click"
              text-color="#333"
              style="height: 100px; border: 0; background-color: #f8f8f8"
              mode="horizontal"
              @select="handleSelect"
              active-text-color="#007BFF"
            >
              <el-menu-item index="1" class="header-title">首页</el-menu-item>
              <el-sub-menu index="2">
                <template #title>
                  <div style="font-size: 18px">定制方案</div>
                </template>
                <el-menu-item index="2-1">高端定制网站解决方案</el-menu-item>
                <el-menu-item index="2-2">全渠道电商营销解决方案</el-menu-item>
                <el-menu-item index="2-3">小程序创新定制解决方案</el-menu-item>
              </el-sub-menu>
              <el-menu-item index="5" class="header-title"
                >关于我们</el-menu-item
              >
            </el-menu>
            <div
              style="
                position: absolute;
                right: -30px;
                top: 50%;
                margin-top: -16px;
              "
            ></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "IsHeader",
};
</script>
<script setup name="IsHeader">
import { onMounted, ref, nextTick, defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";
defineProps({
  activeIndex: {
    type: String,
    required: "1",
  },
});

const router = useRouter();
const route = useRoute();
// const activeIndex = ref("2-1");

let company = {
  qrcode: require("../../../static/other/公众号二维码.jpg"),
};
let scroll = "";
let timer = "";
let seen = true;
const currentMenu = ref("首页");
const currentRoute = ref("");

onMounted(async () => {
  menuSeen();
  currentRoute.value = route.name; // 在组件初始化时获取当前路由名称
  router.afterEach((to) => {
    currentRoute.value = to.name; // 监听路由变化，更新当前路由名称
  });
  console.log(" currentRoute.value", currentRoute.value);
});

const handleSelect = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;
    case "2-1":
      router.push({
        name: "CaseDetail",
        query: {
          row: "1",
        },
      });
      break;
    case "2-2":
      router.push({
        name: "CaseDetail",
        query: {
          row: "2",
        },
      });
      break;
    case "2-3":
      router.push({
        name: "CaseDetail",
        query: {
          row: "3",
        },
      });
      break;
    case "5":
      console.log("跳转到关于车我们");
      router.push({ name: "aboutUs" });
      break;
  }
};
const handleSelectMobile = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;

    case "5":
      router.push({ name: "aboutUs" });
      break;
  }
};
const handleScroll = () => {
  this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
  if (this.scroll > 600) {
    //判断滚动后高度超过400px,就显示
    this.$refs.btn.style.display = "block";
  } else {
    this.$refs.btn.style.display = "none";
  }
};
const backTop = () => {
  //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
  this.timer = setInterval(() => {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let ispeed = Math.floor(-osTop / 5);
    document.documentElement.scrollTop = document.body.scrollTop =
      osTop + ispeed;
    if (osTop === 0) {
      clearInterval(this.timer);
    }
  }, 30);
};
const login = () => {
  window.open("https://admin.fastice-tech.com/login");
};
const register = () => {
  window.open("https://admin.fastice-tech.com/registe");
};
const menuSeen = () => {
  // 屏幕尺寸
  let screenHeight = document.body.clientWidth;
  console.log(screenHeight, "screenHeight");
  if (screenHeight > 415) {
    seen = true;
  } else {
    seen = false;
  }
};
const logoClick = () => {
  currentMenu.value = "首页";
};

// 侧面栏
// 侧面栏-微信咨询
const onToFoxedOneHover = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "block";
  oneFixbox.style.background = "#494949";
};
// 侧面栏微信咨询离开
const handleToFoxedOneLeave = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "none";
  oneFixbox.style.background = "#23282e";
};
// 侧面栏。电话
const onToFoxedTwoHover = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "0";
};
const handleToFoxedTwoLeave = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "136px";
};

// 回到顶部
const srcollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
}; //回到游览器顶部
</script>

<style scoped>
::v-deep .el-menu .el-menu-item {
  /* color: rgba(255, 255, 255, 0.5) !important; */
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  opacity: 1;
  z-index: 99;
  color: #000;
}
::v-deep .el-menu .el-menu-item:hover {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  font-weight: bold;
  border-bottom: 2px solid #000;
  color: #000;
}
::v-deep .el-menu .el-menu-item.is-active {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  opacity: 1;
  color: #000;
  border-bottom: 2px solid #000;
  font-weight: bold;
}
::v-deep .el-menu .el-menu-item.is-active:hover {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  color: #000;
}

.ToFoxed {
  position: fixed;
  right: 0px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
}
/* 底部cart样式 */
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
}
.bottomCart2 {
  display: none;
}
.header2 {
  display: none;
}
@media screen and (max-device-width: 768px) {
  .ToFoxed {
    display: none;
  }
  .header {
    display: none;
  }
  .header2 {
    background-color: #f8f8f8;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    display: block;
  }
  .bottomCart {
    display: none;
  }
  .bottomCart2 {
    display: block;
  }
}
/* 侧边栏固定定位 */
.twoFixbox {
  position: relative;
  left: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
}
.fixBox {
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
}

.threeFixbox:hover {
  width: 77px; /* 变宽一倍 */
}

.fixBox:hover > div:last-child {
  margin-top: 0; /* 取消上边距 */
}

/* 底部cart样式
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
} */

.logo_img {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin-left: 178px;
}
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
.header-title {
  font-size: 1em;
}
.company_name {
  font-weight: normal;
  font-size: 1em;
  margin-left: 10px;
}
.footer_font {
  font-size: 1em;
}
#menu_index {
  /* width: 300px; */
  /* display: flex; */
}
.header {
  position: fixed;
  z-index: 99;
  top: 0;
  background-color: #ffffff;
  /* height: 100px; */
  width: 100%;
}
@media screen and (max-device-width: 415px) {
  #menu_index {
    width: 700px;
    height: 100%;
  }
}
#header-img {
  width: 50px;
  height: 50px;
  margin: auto;
}
.header-logo {
  height: 50px;
  width: 50px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
</style>
