<template>
    <div  style="clear: both; margin-top: 100px ;background-color:#f8f8f8">
      <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<!-- <script setup name="App">
import { onMounted, ref, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
const activeIndex = ref("1");

let company = {
  qrcode: require("../static/other/公众号二维码.jpg"),
};
let scroll = "";
let timer = "";
let seen = true;
const currentMenu = ref("首页");
const currentRoute = ref("");

 onMounted(async () => {
  menuSeen();
  currentRoute.value = route.name; // 在组件初始化时获取当前路由名称
  router.afterEach((to) => {
    currentRoute.value = to.name; // 监听路由变化，更新当前路由名称
  });
  console.log(" currentRoute.value", currentRoute.value);
});

const handleSelect = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;
    case "2-1":
      router.push({
        name: "CaseDetail",
        query: {
          row: "1",
        },
      });
      break;
    case "2-2":
      router.push({
        name: "CaseDetail",
        query: {
          row: "2",
        },
      });
      break;
    case "2-3":
      router.push({
        name: "CaseDetail",
        query: {
          row: "3",
        },
      });
      break;
    case "5":
      console.log("跳转到关于车我们");
      router.push({ name: "aboutUs" });
      break;
  }
};
const handleSelectMobile = (key) => {
  switch (key) {
    case "1":
      router.push({ name: "Index" });
      break;

    case "5":
      router.push({ name: "aboutUs" });
      break;
  }
};
const handleScroll = () => {
  this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
  if (this.scroll > 600) {
    //判断滚动后高度超过400px,就显示
    this.$refs.btn.style.display = "block";
  } else {
    this.$refs.btn.style.display = "none";
  }
};
const backTop = () => {
  //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
  this.timer = setInterval(() => {
    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
    let ispeed = Math.floor(-osTop / 5);
    document.documentElement.scrollTop = document.body.scrollTop =
      osTop + ispeed;
    if (osTop === 0) {
      clearInterval(this.timer);
    }
  }, 30);
};
const login = () => {
  window.open("https://admin.fastice-tech.com/login");
};
const register = () => {
  window.open("https://admin.fastice-tech.com/registe");
};
const menuSeen = () => {
  // 屏幕尺寸
  let screenHeight = document.body.clientWidth;
  console.log(screenHeight, "screenHeight");
  if (screenHeight > 415) {
    seen = true;
  } else {
    seen = false;
  }
};
const logoClick = () => {
  currentMenu.value = "首页";
};

// 侧面栏
// 侧面栏-微信咨询
const onToFoxedOneHover = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "block";
  oneFixbox.style.background = "#494949";
};
// 侧面栏微信咨询离开
const handleToFoxedOneLeave = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "none";
  oneFixbox.style.background = "#23282e";
};
// 侧面栏。电话
const onToFoxedTwoHover = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "0";
};
const handleToFoxedTwoLeave = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "136px";
};

// 回到顶部
const srcollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
}; //回到游览器顶部
</!-->
 
