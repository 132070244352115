<template>
  <div>
    <IsHeader :activeIndex="'2-1'"></IsHeader>
    <div class="container">
      <div class="title" v-show="flag != 2">
        <h2>打造品牌互联网新形象</h2>
      </div>
      <!-- 打造品牌互联网形象 -->
      <div class="casecontainer" v-show="flag == 1">
        <div class="caseBox">
          <img src="../assets/caseDetail/bian01.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">品牌官网建设</h3>
          <div class="centerOne">
            品牌官网形象升级是嗨云网站建设中的基础服务。我们把每一次的官网形象升级改版当成品牌重生或成长，每一次都赋予它一定的意义和担当
          </div>
          <div class="centerTwo">
            通过官方网站，使其能帮助品牌在互联网实现品牌理念的最大程度诠释、最美第一视觉呈现和最优交互体验。
          </div>
        </div>
        <div class="caseBox">
          <img src="../assets/caseDetail/bian02.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">集团官网建设</h3>
          <div class="centerOne">
            集团及其众多子公司并行时，站点众多且单站建设不仅成本高昂，管理复杂，各自为战，品牌形象没有统一传递。造成集团与子公司关联度较低。
          </div>
          <div class="centerTwo">
            嗨云通过自有的品牌视觉语言理论为集团公司建立统一品牌形象，分权管理，深度整合帮助集团企业打造属于其专有的互联网传播视觉语言。
          </div>
        </div>
        <div class="caseBox">
          <img src="../assets/caseDetail/bian03.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">企业官网建设</h3>
          <div class="centerOne">
            通过官网塑造企业品牌高度企业在发展，环境在变化，促使品牌在每个阶段都应该有属于它的定位。
          </div>
          <div class="centerTwo">
            基于您的品牌，挖掘品牌优势基因，为品牌规划中短期互联网品牌传播定位，我们把您当作战略伙伴。们需要创新，您的品牌也需要创新。
          </div>
        </div>
        <div class="caseBox">
          <img src="../assets/caseDetail/bian04.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">政府互联网形象官宣</h3>
          <div class="centerOne">
            政府互联网形象塑造展现独特气质,政府项目采购与社会行业项目不同，有其独特的特点。有鲜明的政治性和对项目过程中严格的标准要求以及系统安全性要求。
          </div>
          <div class="centerTwo">
            通过与中国航天、中国人民解放军海军、辞书出版社、上海献血等政府单位的合作，总结和沉淀出不同属性的政府单位互联网品牌传播，所具备的不同气质和执行经验。
          </div>
        </div>
      </div>
      <!-- 打造品牌互联网形象 -->
      <div v-show="flag == 2">
        <!-- 新零售电商 -->
        <img
          src="../assets/caseDetail/two/banenr22.png"
          alt=""
          style="width: 100%"
        />
        <!-- 四种技术 -->
        <div
          style="
            display: flex;
            width: 100%;
            justify-content: space-around;
            margin-top: 40px;
          "
        >
          <div style="display: flex">
            <div>
              <img src="../assets/caseDetail/two/zu01.png" alt="" />
            </div>
            <div>
              <h2>JAVA等技术</h2>
              <div>多中国主流语言版本可选择</div>
            </div>
          </div>
          <div style="display: flex">
            <div>
              <img src="../assets/caseDetail/two/zu02.png" alt="" />
            </div>
            <div>
              <h2>全渠道布局</h2>
              <div>线上线下统一管理</div>
            </div>
          </div>
          <div style="display: flex">
            <div>
              <img src="../assets/caseDetail/two/zu03.png" alt="" />
            </div>
            <div>
              <h2>数字中台支撑</h2>
              <div>用户+财务+业务+数据中台</div>
            </div>
          </div>
          <div style="display: flex">
            <div>
              <img src="../assets/caseDetail/two/zu04.png" alt="" />
            </div>
            <div>
              <h2>支持多业务模式</h2>
              <div>S2B2C/B2B2C/B2B/O2O</div>
            </div>
          </div>
        </div>
        <!-- 跳动 -->
        <div style="text-align: center; position: relative; margin-top: 40px">
          <img src="../assets/caseDetail/two/zubg.png" alt="" />
          <img
            src="../assets/caseDetail/two/zumk.png"
            alt=""
            class="image-container"
            style="
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -245px;
              margin-top: -204px;
            "
          />
        </div>
      </div>
      <!-- 打造品牌互联网形象 -->
      <div class="casecontainer" v-show="flag == 3">
        <div class="caseBox" style="text-align: center">
          <img src="../assets/caseDetail/bian01.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">电商微模式</h3>
          <div class="centerOne">产品管理/订单管理/商品管理</div>
          <div class="centerTwo">
            采购管理/库存管理/仓库管理 发货管理/财务管理
          </div>
        </div>
        <div class="caseBox" style="text-align: center">
          <img src="../assets/caseDetail/bian02.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">营销微模块</h3>
          <div class="centerOne">三级分销/商品预售/买赠</div>
          <div class="centerTwo">秒杀活动/优惠劵</div>
        </div>
        <div class="caseBox" style="text-align: center">
          <img src="../assets/caseDetail/bian03.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">教育微模式</h3>
          <div class="centerOne">试卷管理/试题管理</div>
          <div class="centerTwo">题库管理/考试管理/知识预览</div>
        </div>
        <div class="caseBox" style="text-align: center">
          <img src="../assets/caseDetail/bian04.png" alt="" class="caseimg" />
          <h3 style="width: 100%; text-align: center">通用微模式</h3>
          <div class="centerOne">直播/视频/文件存蓄 语音识别/自然语言</div>
          <div class="centerTwo">
            知识图谱/智能条码/GPS定位 蓝牙传输/WIFI传输
          </div>
        </div>
      </div>
      <div class="title" v-show="flag != 2">
        <h2>嗨云案例展示</h2>
      </div>
      <!-- 经典案例封面轮播图 -->
      <div class="swiper" v-show="flag == 1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/1.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/2.png"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/3.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/4.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/5.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/6.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="swiper" v-show="flag == 3">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/111.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/222.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/333.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/444.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/555.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/ClassicCase/666.jpg"
              alt=""
              style="height: 100%; width: 100%"
            />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
      <div class="title">
        <h2>他们信任嗨云</h2>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          width: 1500px;
          margin: 20px auto;
        "
      >
        <div style="overflow: hidden">
          <img src="../assets/Logo/brand1.jpg" alt="" class="brandImg" />
        </div>
        <div style="overflow: hidden">
          <img src="../assets/Logo/brand2.jpg" alt="" class="brandImg" />
        </div>
        <div style="overflow: hidden">
          <img src="../assets/Logo/brand3.png" alt="" class="brandImg" />
        </div>
        <div style="overflow: hidden">
          <img src="../assets/Logo/brand4.jpg" alt="" class="brandImg" />
        </div>
        <div style="overflow: hidden">
          <img src="../assets/Logo/brand5.png" alt="" class="brandImg" />
        </div>
      </div>
    </div>
    <IsFooter></IsFooter>
  </div>
</template>

<script>
export default {};
</script>
<script setup name="CaseDetail">
import Swiper from "./swiper-bundle.min.js";
import "./swiper-bundle.min.css";
import { onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import IsHeader from "./fixed/IsHeader.vue";
import IsFooter from "./fixed/IsFooter.vue";
const route = useRoute();
const flag = ref("");
onMounted(() => {
  new Swiper(".swiper", {
    loop: true,
    slidesPerView: 2,
    slidesPerGroup: 1,
    // 如果需要前进后退按钮  effect: 'coverflow',
    //   effect: 'flip',
  });
  //   flag.value=route.query.row
});

watch(
  route,
  () => {
    flag.value = route.query.row;
  },
  { immediate: true }
);
</script>

<style scoped>
.image-container {
  position: relative;
  animation: jump 2s infinite;
}

@keyframes jump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.swiper {
  width: 100%;
  height: 580px;
}
.swiper-slide {
  background: lightcoral;
}
.casecontainer {
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/caseDetail/back01.jpg");
}
.caseBox {
  color: #fff;
  padding: 20px;
  width: 380px;
  height: 280px;
  background-color: #1d93eb;
  padding-top: 100px;
  line-height: 22px;
  position: relative;
  margin-left: 17px;
}
.caseimg {
  width: 120px;
  height: 139px;
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -60px;
  transition: all 0.3s linear;
}
.caseimg:hover {
  transform: rotate(360deg) scale(1.2, 1.2);
}
.centerOne {
  font-size: 14px;
}
.centerTwo {
  font-size: 15px;
  margin-top: 30px;
}
.title {
  padding: 40px 0 10px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
}
.title h2:after {
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: block;
  content: "";
  width: 60px;
  height: 3px;
  margin-left: -30px;
  background: #007bff;
}
.brandImg {
  width: 220px;
  height: 110px;
}
.brandImg:hover {
  transform: scale(1.2, 1.2);
  transition: 0.5s;
}
</style>
