<template>
  <!-- <div style="height: 595px"> -->
  <div id="about-coseast-wrap">
    <div class="about-coseast" v-if="detailIndex == 0">
      <h1 class="p1">GOLDEN DIEYUN PROJECT</h1>
      <h2 class="p2">金蝶云项目</h2>
      <article class="p4">项目简介。。。</article>
      <article class="p4"></article>
      <article class="p4">
        <span class="title"> 个人中心模块： </span
        >用户信息、我的授权、授权查询、发展代理、订单审核、货款审核、待我发货、
        申请取消；
      </article>
      <article class="p4">
        <span class="title"> 订单管理：</span>
        在线下单、我的订单、团队订单、话动订单；
      </article>
      <article class="p4">
        <span class="title"> 团队管理： </span>
        我要升级、我的团队、加入审核、升级审核：
      </article>
      <article class="p4">
        <span class="title"> 货款管理： </span>
        我的货款，代理货款、货款审核、货款明细。
      </article>
    </div>
    <div class="about-coseast" v-if="detailIndex == 1">
      <h1 class="p1">ORDERING PLATFORM PROJECT</h1>
      <h2 class="p2">贝德美订货平台</h2>
      <article class="p4">简介。。。。。。。。。。。</article>
      <article class="p4">
        微商新零售SAAS项目，用户通过分享的二维码进行注册申请代理商、发展下级、团队。
        主要业务功能分为大模块：
      </article>
      <article class="p4">
        <span class="title"> 个人中心模块： </span
        >用户信息、我的授权、授权查询、发展代理、订单审核、货款审核、待我发货、
        申请取消；
      </article>
      <article class="p4">
        <span class="title"> 订单管理：</span>
        在线下单、我的订单、团队订单、话动订单；
      </article>
      <article class="p4">
        <span class="title"> 团队管理： </span>
        我要升级、我的团队、加入审核、升级审核：
      </article>
      <article class="p4">
        <span class="title"> 货款管理： </span>
        我的货款，代理货款、货款审核、货款明细。
      </article>
    </div>
    <div class="about-coseast" v-if="detailIndex == 2">
      <h1 class="p1">RETAIL SAAS PROJECT</h1>
      <h2 class="p2">微商新零售SAAS项目</h2>
      <article class="p4">
        微商新零售系统按照代理商的级别显示不同的商品购买价格，代理商购买商品后，需要他的上级链条进行审核。
      </article>
      <article class="p4">
        微商新零售SAAS项目，用户通过分享的二维码进行注册申请代理商、发展下级、团队。
        主要业务功能分为大模块：
      </article>
      <article class="p4">
        <span class="title"> 个人中心模块： </span
        >用户信息、我的授权、授权查询、发展代理、订单审核、货款审核、待我发货、
        申请取消；
      </article>
      <article class="p4">
        <span class="title"> 订单管理：</span>
        在线下单、我的订单、团队订单、话动订单；
      </article>
      <article class="p4">
        <span class="title"> 团队管理： </span>
        我要升级、我的团队、加入审核、升级审核：
      </article>
      <article class="p4">
        <span class="title"> 货款管理： </span>
        我的货款，代理货款、货款审核、货款明细。
      </article>
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
export default {
  name: "CaseSliders2Detail",
};
</script>
<script setup>
import { onMounted, ref } from "vue";

import { useRouter, useRoute } from "vue-router";
const detailIndex = ref("");
const route = useRoute();
onMounted(() => {
  console.log(route.query.index, route.query);
  detailIndex.value = route.query.index;
});
</script>

<style scoped>
#about-coseast-wrap {
  height: 570px;
}
.about-coseast {
  width: 1200px;
  margin: 0 auto;
}
#about-coseast-wrap .about-coseast .p1 {
  font-family: Novecentowide-Medium;
  color: rgba(0, 0, 0, 0.29);
  font-weight: 500;
  font-size: 39px;
  height: 29px;
  line-height: 29px;
}
#about-coseast-wrap .about-coseast .p2 {
  font-family: PingFang-SC-Bold;
  font-size: 38px;
  height: 36px;
  line-height: 36px;
  margin-top: 26px;
  color: #585858;
}
#about-coseast-wrap .about-coseast .p4 {
  /* font-family: PingFang-SC-Regular; */
  font-weight: 400;
  font-size: 16px;
  opacity: 0.9;
  margin-top: 30px;
  line-height: 30px;
  color: black;
}
.title {
  font-weight: bold;
  color: #585858;
}
</style>
