import request from './network.js';


// 咨询业务接口
export function wxSoftwareConsult (parms) {
    return request({
        url: '/wx/software/consult',
        method: 'POST',
        data: {
            ...parms
        }
    });
}