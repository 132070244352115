<template>
<div>
  <IsHeader :activeIndex="'5'"></IsHeader>
  <div class="aboutUs" style="margin-top: 120px">
    <div class="containerWidth">
      <div class="Content">
      <div class="title">公司简介</div>
        我们是一家专注于为企业提供高质量IT外包服务的公司。我们的团队由一群充满热情和创造力的专业人士组成，他们拥有多年的IT行业经验和丰富的技术知识。我们的目标是为客户提供最好的解决方案，以实现其业务目标和成功。
        我们提供多样化的IT服务，包括应用程序开发，小程序开发，IT咨询，数据分析，IT支持和维护等，我们秉承诚信和创新的价值观，致力于不断提升自己的技术水平和服务质量。我们相信，只有客户的成功才是我们的成功，
        因此我们始终坚持以客户为中心的理念，为客户提供最好的服务。
      </div>
      <div class="Content">
        <div class="title">企业从事于：</div>
        互联网整合营销，互联网软件开发，微信公众号，小程序开发，微商城开发，提供技术支持，是所创业型服务研发公司。
      </div>

      <div class="Content">
        <div class="title">核心理念：</div>
        打造移动电子商务平台，提供一键式部署，实现简单可靠稳定的商务平台。
      </div>
      <div class="Content">
        <div class="title">团队作风：</div>
        我们来自不同地区，因梦想相聚，组建团队，每个团队成员如同人的五官，缺一不可。
      </div>
      <div class="Content">
        <div class="title">企业精神：</div>
        以实力为盾，自信为矛。我们坚信成功靠朋友，成长靠对手，成就靠团体。
      </div>
    </div>
    <div>
      <!-- <img class="aboutUsImg" src="https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/assets/aboutUs.jpg" alt="" /> -->
    </div>
  </div>
   <IsFooter></IsFooter>
</div>
 
  <!-- 企业从事于：互联网整合营销，互联网软件开发，微信公众号，小程序，微商城开发，提供技术支持，是所创业型服务研发公司。

核心理念：打造移动电子商务平台 ，提供一键式部署，实现简单可靠稳定的商务平台。

团队作风：我们来自不同地区，因梦想相聚，组建团队，每个团队成员如同人的五官，缺一不可。

企业精神：以实力为盾，自信为矛。我们坚信成功靠朋友，成长靠对手，成就靠团体。 -->
</template>

<script>
export default {};
</script>

<script setup>
import IsHeader from "./fixed/IsHeader.vue";
import IsFooter from "./fixed/IsFooter.vue";
</script>

<style scoped>
.aboutUsImg {
  width: 650px;
  height: 500px;
  margin-left: 80px;
}
.aboutUs {
  display: flex;
  align-items: center;
  /* width: 1200px; */
  padding: 10px 150px;
  margin: 0 auto;
}
.title {
  font-size: 25px;
  /* font-weight: var(--groot-ui-font-weight-2); */
  margin-bottom: 20px;
}

.Content {
  line-height: 30px;
  margin-bottom: 10px;
}
.containerWidth {
  width: 60%;
}
@media screen and (max-device-width: 915px) {
  .aboutUsImg {
    width: 50px;
    height: 50px;
    margin-left: 0px;
  }
  .aboutUs {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
  }
  .containerWidth{
    width: 90%;
  }
  .Content{
    padding-left: 30px;
  }
}
</style>
