<template>
   <div id="app" style="background-color: #f8f8f8">
    <div style="width: 90%; margin: 20px auto">
      <div style="height: 1px; padding: 0; background-color: #999"></div>
    </div>
    <div style="width: 100%; margin: 20px auto" class="bottomCart">
      <el-row>
        <el-col :span="14">
          <div style="margin-left:178px;color: color: rgba(255,255,255,0.48);">
            <p class="footer_font">Tel:15990103290</p>
            <p class="footer_font">邮箱：lizhigang@coseast.com</p>
            <div style="margin: auto" class="footer_font">
              AD:浙江省杭州市拱墅区丰潭路380号城西银泰E座2幢8F
            </div>
          </div>
          <!-- <el-divider /> -->
        </el-col>
        <el-col :span="10">
          <div style="text-algin: center">
            <div>
              <img
                :src="company.qrcode"
                style="width: 100px; height: 100px; border: 0"
              />
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 90%; margin: 20px auto">
        <div style="height: 1px; padding: 0; background-color: #999"></div>
      </div>
      <div style="margin: auto; margin-left: 178px" class="footer_font">
        © All Rights Reserved 浙江杭州妆点信息技术有限公司 版权所有
        浙ICP备15043724号-1
      </div>
      <div></div>
    </div>
    <!-- 2 -->
    <div style="width: 100%; background: #494949" class="bottomCart2">
      <div
        style="
          color: rgba(255, 255, 255, 0.48);
          text-align: center;
          padding: 20px 10px;
        "
      >
        <div
          style="
            margin: 0 auto;
            color: rgba(255, 255, 255, 0.48);
            font-size: 10px;
            line-height: 20px;
          "
        >
          ©杭州妆点信息技术有限公司 版权所有 浙ICP备15043724号-1
        </div>
        <div style="margin: 0 auto; font-size: 10px; line-height: 20px">
          AD:浙江省杭州市拱墅区丰潭路380号城西银泰E座2幢8F
        </div>
        <div style="font-size: 10px; line-height: 20px">
          邮箱：lizhigang@coseast.com
        </div>
        <div style="font-size: 10px; line-height: 20px">Tel:15990103290</div>
      </div>
    </div>
  </div>
  <!-- 固定定位 -->
  <div class="ToFoxed">
    <!-- 1 -->
    <div
      class="fixBox oneFixbox"
      style="position: relative"
      @mouseenter="onToFoxedOneHover"
      @mouseleave="handleToFoxedOneLeave"
    >
      <div>
        <i class="iconfont icon-weixin"></i>
      </div>
      <div style="margin-top: 5px">微信咨询</div>
      <div
        class="peopleCard"
        style="position: absolute; left: -140px; top: 0px; display: none"
      >
        <img
          src="../../assets/FixedPicture/二维码.jpg"
          style="width: 140px; height: 140px"
        />
      </div>
    </div>
    <!-- 2 -->
    <div
      class="twoFixbox"
      @mouseenter="onToFoxedTwoHover"
      @mouseleave="handleToFoxedTwoLeave"
    >
      <div style="text-align: center; width: 65px">
        <i class="iconfont icon-24gf-phoneBubble"> </i>
        <div style="margin-top: 5px">电话</div>
      </div>
      <div
        class="peopelTel"
        style="
          text-align: center;
          border-left: 1px solid #fff;
          line-height: 70px;
          width: 135px;
        "
      >
        15990103290
      </div>
    </div>
    <!-- 3 -->
    <div class="fixBox threeFixbox" @click="srcollToTop">
      <div>
        <i class="iconfont icon-zhiding" style="text-algin: center"></i>
      </div>
    </div>
    </div>
</template>


<script>
export default {
  name: "Isfooter",
};
</script>

<script setup name="Isfooter">
let company = {
  qrcode: require("../../../static/other/公众号二维码.jpg"),
};
let scroll = "";
let seen = true;
const handleScroll = () => {
  this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
  if (this.scroll > 600) {
    //判断滚动后高度超过400px,就显示
    this.$refs.btn.style.display = "block";
  } else {
    this.$refs.btn.style.display = "none";
  }
};

const menuSeen = () => {
  // 屏幕尺寸
  let screenHeight = document.body.clientWidth;
  console.log(screenHeight, "screenHeight");
  if (screenHeight > 415) {
    seen = true;
  } else {
    seen = false;
  }
};

// 侧面栏
// 侧面栏-微信咨询
const onToFoxedOneHover = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "block";
  oneFixbox.style.background = "#494949";
};
// 侧面栏微信咨询离开
const handleToFoxedOneLeave = () => {
  const peopleCard = document.querySelector(".peopleCard");
  const oneFixbox = document.querySelector(".oneFixbox");
  peopleCard.style.display = "none";
  oneFixbox.style.background = "#23282e";
};
// 侧面栏。电话
const onToFoxedTwoHover = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "0";
};
const handleToFoxedTwoLeave = () => {
  const twoFixbox = document.querySelector(".twoFixbox");
  twoFixbox.style.left = "136px";
};

// 回到顶部
const srcollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
}; //回到游览器顶部
</script>

<style scoped>
::v-deep .el-menu .el-menu-item {
  /* color: rgba(255, 255, 255, 0.5) !important; */
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  opacity: 1;
  z-index: 99;
  color: #000;
}
::v-deep .el-menu .el-menu-item:hover {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  font-weight: bold;
  border-bottom: 2px solid #000;
  color: #000;
}
::v-deep .el-menu .el-menu-item.is-active {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  opacity: 1;
  color: #000;
  border-bottom: 2px solid #000;
  font-weight: bold;
}
::v-deep .el-menu .el-menu-item.is-active:hover {
  font-family: PingFang-SC-Medium;
  font-size: 19px;
  color: #000;
}

.ToFoxed {
  position: fixed;
  right: 0px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 99;
}
/* 底部cart样式 */
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
}
.bottomCart2 {
  display: none;
}
.header2 {
  display: none;
}
@media screen and (max-device-width: 568px) {
  .ToFoxed {
    display: none;
  }
  .header {
    display: none;
  }
  .header2 {
    background-color: #f8f8f8;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    display: block;
  }
  .bottomCart {
    display: none;
  }
  .bottomCart2 {
    display: block;
  }
}
/* 侧边栏固定定位 */
.twoFixbox {
  position: relative;
  left: 136px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
  transition: all 0.5s;
}
.fixBox {
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 70px;
  border-bottom: 1px solid #000;
  border-top: 1px solid #444;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgb(255 255 255 / 10%);
  background: #23282e;
  color: #fff;
  font-size: 14px;
}

.threeFixbox:hover {
  width: 77px; /* 变宽一倍 */
}

.fixBox:hover > div:last-child {
  margin-top: 0; /* 取消上边距 */
}

/* 底部cart样式
.bottomCart {
  background: #494949;
  color: rgba(255, 255, 255, 0.48);
  font-family: PingFang-SC-Light;
  width: 100vw;
  padding: 60px 0;
  font-size: 16px;
} */

.logo_img {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  margin-left: 178px;
}
#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
}
.header-title {
  font-size: 1em;
}
.company_name {
  font-weight: normal;
  font-size: 1em;
  margin-left: 10px;
}
.footer_font {
  font-size: 1em;
}
#menu_index {
  /* width: 300px; */
  /* display: flex; */
}
.header {
  position: fixed;
  z-index: 99;
  top: 0;
  background-color: #ffffff;
  /* height: 100px; */
  width: 100%;
}
/* @media screen and (max-device-width: 415px) {
  .logo_img {
    float: left;
    width: auto;
    cursor: pointer;
  }
  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 5px;
    cursor: pointer;
  }
  .footer_font {
    font-size: 0.875em;
  }
  #menu_index {
    float: right;
    width: auto;
    height: 100%;
  }
  .mobile_menu {
  }
} */
#header-img {
  width: 50px;
  height: 50px;
  margin: auto;
}
.header-logo {
  height: 50px;
  width: 50px;
  align-self: center;
}

.footer {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 90%;
}
a {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
</style>
