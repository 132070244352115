import { createRouter, createWebHashHistory } from "vue-router";
import Index from "../components/Index";
import aboutUs from "../components/aboutUs";
import CaseSliders2Detail from "../components/CaseSliders2Detail";
import CaseDetail from "../components/CaseDetail";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: aboutUs,
  },
  {
    path: "/CaseSliders2Detail",
    name: "CaseSliders2Detail",
    component: CaseSliders2Detail,
  },
  {
    path: "/CaseDetail",
    name: "CaseDetail",
    component: CaseDetail,
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

export default router;
