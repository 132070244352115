<template>
  <!--  -->
  <div>
    <IsHeader :activeIndex="'1'"> </IsHeader>
    <div onselectstart="return false;">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(banner, index) in sliders"
            :key="index"
          >
            <div v-if="index == 0" style="text-align: center">
              <div class="oneImg">
                <div style="font-size: 136px; font-weight: bold">DREAME</div>
                <div style="font-size: 104px; font-weight: bold">
                  CREATE FUTURE
                </div>
                <div style="font-size: 84px; font-weight: bold">
                  梦想创造未来
                </div>
              </div>
              <div class="oneImg2">
                <div style="font-size: 30px; font-weight: bold">DREAME</div>
                <div style="font-size: 24px; font-weight: bold">
                  CREATE FUTURE
                </div>
                <div style="font-size: 24px; font-weight: bold">
                  梦想创造未来
                </div>
              </div>
              <img :src="banner.img" class="MobileImg" style="width: 100%" />
            </div>
            <div v-else>
              <img :src="banner.img" class="MobileImg" style="width: 100%" />
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination paginationBottom"></div>
      </div>
    </div>

    <div class="title">
      <h2>一站式服务</h2>
    </div>
    <!-- 盒子 -->
    <div class="border_parent" style="display: flex">
      <div class="border">
        <div class="border_img_parent">
          <img
            :src="images[1].img"
            class="img_border"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="border_title">{{ images[1].title }}</div>
        <div class="border_content_one">{{ images[1].content[0] }}</div>
      </div>

      <div class="border">
        <div class="border_img_parent">
          <img
            :src="images[2].img"
            class="img_border"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="border_title">{{ images[2].title }}</div>
        <div class="border_content_one">{{ images[2].content[0] }}</div>
        <div class="border_content_two">{{ images[2].content[1] }}</div>
      </div>

      <div class="border">
        <div class="border_img_parent">
          <img
            :src="images[3].img"
            class="img_border"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="border_title">{{ images[3].title }}</div>
        <div class="border_content_one">{{ images[3].content[0] }}</div>
        <div class="border_content_two">{{ images[3].content[1] }}</div>
      </div>

      <div class="border" style="margin-right: 0">
        <div class="border_img_parent">
          <img
            :src="images[4].img"
            class="img_border"
            style="width: 100%; height: 100%"
          />
        </div>
        <div class="border_title">{{ images[4].title }}</div>
        <div class="border_content_one">{{ images[4].content[0] }}</div>
        <div class="border_content_two">{{ images[4].content[1] }}</div>
      </div>
    </div>
    <!-- 产品微观介绍 -->
    <div class="title">
      <h2>宗旨</h2>
    </div>

    <!-- 后台管理 -->
    <div class="product_pc">
      <el-row>
        <el-col :xs="24" :sm="24">
          <div style="position: relative; text-align: center">
            <div class="PurposeTitle1">为伙伴提供创新解决方案</div>
            <div class="PurposeTitle2">共同实现业务增长与成功</div>
            <img :src="manages[0].img[0]" class="product_pc_img" />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 产品特点-->
    <div class="title">
      <h2>核心优势</h2>
    </div>
    <!-- <div class="title" style="padding: 0">
      <div>我们的团队拥有最核心的技术，能给您最优的解决方案</div>
    </div> -->
    <div class="product_spec_parent">
      <div class="border_spec">
        <div class="border_spec_inner_one">
          <img :src="spec.product[0].img" style="width: 50px; height: 50px" />
        </div>
        <div style="text-align: center" class="font_size_24">
          {{ spec.product[0].title }}
        </div>
        <div style="display: flex; flex-direction: column">
          <div class="border_spec_content_one">
            {{ spec.product[0].contents[0] }}
          </div>
          <div class="border_spec_inner_last">
            {{ spec.product[0].contents[1] }}
          </div>
        </div>
      </div>

      <div class="border_spec">
        <div class="border_spec_inner_one">
          <img :src="spec.product[1].img" style="width: 50px; height: 50px" />
        </div>
        <div style="text-align: center" class="font_size_24">
          {{ spec.product[1].title }}
        </div>
        <div style="display: flex; flex-direction: column">
          <div class="border_spec_content_one">
            {{ spec.product[1].contents[0] }}
          </div>
          <div class="border_spec_inner_last">
            {{ spec.product[1].contents[1] }}
          </div>
        </div>
      </div>

      <div class="border_spec">
        <div class="border_spec_inner_one">
          <img :src="spec.product[2].img" style="width: 50px; height: 50px" />
        </div>
        <div style="text-align: center" class="font_size_24">
          {{ spec.product[2].title }}
        </div>
        <div style="display: flex; flex-direction: column">
          <div class="border_spec_content_one">
            {{ spec.product[2].contents[0] }}
          </div>
          <div class="border_spec_inner_last">
            {{ spec.product[2].contents[1] }}
          </div>
        </div>
      </div>

      <div class="border_spec" style="margin-right: 0">
        <div class="border_spec_inner_one">
          <img :src="spec.product[3].img" style="width: 50px; height: 50px" />
        </div>
        <div style="text-align: center" class="font_size_24">
          {{ spec.product[3].title }}
        </div>
        <div style="display: flex; flex-direction: column">
          <div class="border_spec_content_one">
            {{ spec.product[3].contents[0] }}
          </div>
          <div class="border_spec_inner_last">
            {{ spec.product[3].contents[1] }}
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- 后加的经典案例 -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 85%;
        margin: 0 auto;
      "
    >
      <div style="width: 50%">
        <div class="classCaseBox" @click="ToclassCaseBox(1)">
          <div>
            <img src="../assets/ClassicCase/ju05.png" alt="" class="Isblue" />
            <img
              src="../assets/ClassicCase/ju05_hui.png"
              alt=""
              class="Isfff"
            />
          </div>
          <div style="padding: 0px 80px 10px 80px">
            <h2>高端定制网站解决方案</h2>
            <div>重塑价格,打造品牌的互联网形象</div>
          </div>
        </div>
        <div
          style="margin-top: 50px"
          class="classCaseBox"
          @click="ToclassCaseBox(2)"
        >
          <div>
            <img src="../assets/ClassicCase/ju06.png" alt="" class="Isblue" />
            <img
              src="../assets/ClassicCase/ju06_hui.png"
              alt=""
              class="Isfff"
            />
          </div>
          <div style="margin: 0px 80px 10px 80px">
            <h2>全渠道电商营销解决方案</h2>
            <div>用户行为数据分析千人千面精准营销</div>
          </div>
        </div>
        <div
          style="margin-top: 50px"
          class="classCaseBox"
          @click="ToclassCaseBox(3)"
        >
          <div>
            <img src="../assets/ClassicCase/ju02.png" alt="" class="Isblue" />
            <img
              src="../assets/ClassicCase/ju02_hui.png"
              alt=""
              class="Isfff"
            />
          </div>
          <div style="margin: 0px 80px 10px 80px">
            <h2>小程序创新定制解决方案</h2>
            <div>一站式设计开发服务助力您的小程序淘金事业</div>
          </div>
        </div>
      </div>
      <div @mouseenter="imgEnter" @mouseleave="imgLeave" style="width:45%">
        <div style="overflow: hidden">
          <img src="../assets/ClassicCase/anli.jpg" alt="" class="classRight" />
        </div>
        <h2 id="myDiv" style="transition: all 0.7s linear">产品研发服务</h2>
        <div>行业沉淀，工匠初心，让交付更有保障。</div>
      </div>
    </div>

    <div class="title">
      <h2>联系我们</h2>
    </div>
    <div class="callFooter">
      <div class="callme">
        <h3 style="color: #fff">
          共同探讨更多细节和需求，为您提供最优质的服务和支持。
        </h3>
        <h2 style="color: #fff">欢迎联系我们，我们期待与您沟通</h2>
        <h3 style="color: #fff">Telephone:15990103290</h3>
      </div>
      <div class="callme2">
        <h3 style="color: #fff; font-size: 13px">
          共同探讨更多细节和需求，为您提供最优质的服务和支持。
        </h3>
        <h2 style="color: #fff; font-size: 18px">
          欢迎联系我们，我们期待与您沟通
        </h2>
        <h3 style="color: #fff; font-size: 16px">Telephone:15990103290</h3>
      </div>
    </div>

    <!-- 助力各行业品牌logo -->=
    <div class="title">
      <h2>助力各行业，各品牌发展</h2>
    </div>
    <!-- 各品牌logo -->
    <div
      style="
        display: flex;
        justify-content: space-between;
        width: 85%;
        margin: 20px auto;
      "
    >
      <div style="overflow: hidden">
        <img src="../assets/Logo/brand1.jpg" alt="" class="brandImg" />
      </div>
      <div style="overflow: hidden">
        <img src="../assets/Logo/brand2.jpg" alt="" class="brandImg" />
      </div>
      <div style="overflow: hidden">
        <img src="../assets/Logo/brand3.png" alt="" class="brandImg" />
      </div>
      <div style="overflow: hidden">
        <img src="../assets/Logo/brand4.jpg" alt="" class="brandImg" />
      </div>
      <div style="overflow: hidden">
        <img src="../assets/Logo/brand5.png" alt="" class="brandImg" />
      </div>
    </div>
    <!-- 定制方案 -->
    <div class="title">
      <h2>定制方案</h2>
    </div>
    <div class="toCallBottom1">
      <div class="Customizedscheme">
        <div class="mobile-block">
          <div class="code-icon"></div>
          <input
            class="mobile-input"
            placeholder="请输入您的称呼"
            placeholder-class="placehoder"
            style="border: none; outline: none; width: 578px"
            maxlength="20"
            v-model="param.memberName"
          />
        </div>
        <div class="mobile-block mobile-block2">
          <div class="mobile-icon"></div>
          <input
            style="border: none; outline: none"
            class="mobile-input"
            placeholder="请输入手机号"
            placeholder-class="placehoder"
            type="number"
            maxlength="11"
            v-model="param.mobile"
          />
        </div>
      </div>
      <div class="mobile-block" style="margin: 20px auto">
        <input
          style="border: none; outline: none"
          class="mobile-input"
          placeholder="请输入您的业务需求"
          placeholder-class="placehoder"
          v-model="param.consultContent"
        />
      </div>
      <div style="margin: 0 auto; width: 500px">
        <el-button
          style="
            width: 500px;
            font-size: 27px;
            border: none;
            color: #fff;
            padding: 40px 20px;
            border-radius: 3px;
            background-color: #e6322e;
            font-family: Novecentowide-DemiBold;
            white-space: pre-line;
          "
          @click="MakeAnAppointmentNow"
        >
          立 即 预 约
        </el-button>
      </div>
    </div>
    <!-- 2 -->
    <div class="toCallBottom2">
      <div class="">
        <div class="mobile-block" style="height: 60px; margin-top: 20px">
          <div class="code-icon"></div>
          <input
            class="mobile-input"
            placeholder="请输入您的称呼"
            placeholder-class="placehoder"
            style="border: none; outline: none"
            maxlength="20"
            v-model="param.memberName"
          />
        </div>
        <div class="mobile-block" style="height: 60px; margin-top: 20px">
          <div class="mobile-icon"></div>
          <input
            style="border: none; outline: none"
            class="mobile-input"
            placeholder="请输入手机号"
            placeholder-class="placehoder"
            type="number"
            maxlength="11"
            v-model="param.mobile"
          />
        </div>
      </div>
      <div class="mobile-block" style="height: 60px; margin-top: 20px">
        <input
          style="border: none; outline: none"
          class="mobile-input"
          placeholder="请输入您的业务需求"
          placeholder-class="placehoder"
          v-model="param.consultContent"
        />
      </div>
      <div style="margin: 0 auto" class="toCallBottom2">
        <el-button
          style="
            font-size: 17px;
            border: none;
            color: #fff;
            width: 100%;
            height: 60px;
            padding: 20px 80px;
            border-radius: 3px;
            background-color: #e6322e;
            font-family: Novecentowide-DemiBold;
            white-space: pre-line;
            margin-top: 20px;
          "
          @click="MakeAnAppointmentNow"
        >
          立 即 预 约
        </el-button>
      </div>
    </div>
    <IsFooter> </IsFooter>
  </div>
</template>

<script>
export default {
  components: { IsFooter },
  name: "HellowWord",
  props: {
    msg: String,
  },
  computed: {
    //计算高度
    computeHeight() {
      let screenHeight = document.body.clientWidth;
      console.log("qqeqeq", screenHeight);
      if (screenHeight > 415) {
        return "550px";
      } else {
        return "250px";
      }
    },
  },
};
</script>
<script setup>
import { wxSoftwareConsult } from "@/api/index";
import Swiper from "./swiper-bundle.min.js";
import IsHeader from "./fixed/IsHeader.vue";
import IsFooter from "./fixed/IsFooter.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";

const router = useRouter();
const caseborder_top = ref(false);
const currentIndex = ref(0);
const isTruetabs = ref(1);
const loadedCountOne = ref(2);
const addMoreCaseText = ref("加载更多");
const param = ref({
  mobile: "",
  memberName: "",
  consultContent: "",
});

onMounted(() => {
  new Swiper(".swiper", {
    loop: true, // 循环模式选项
    autoplay: {
      delay: 5000, //1秒切换一次
    },
    // 如果需要分页器
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      bulletClass: "swiper-pagination-bullet", //需设置.my-bullet样式 //分式类型分页器的当前索引的类名
      bulletActiveClass: "myswiper-pagination-bullet-active", //需设置.my-bullet样式 //分式类型分页器的当前索引的类名
    },

    // 如果需要前进后退按钮
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
});
// 死数据轮播
const sliders = [
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/slider/swiper1.jpg",
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/slider/swiper2.jpg",
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/slider/swiper3.jpg",
  },
];
// 死数据，一站式服务
const images = [
  {
    title: "软件开发",
    content: ["用户消费分析", "用户标签设定"],
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/Service1.png",
    title: "后台管理开发",
    content: [
      "我们旨在为企业提供高效的业务管理解决方案，为其设计和实现功能丰富、易于使用、高效稳定的后台管理系统。我们掌握前沿的前端和后端技术，注重代码质量和可维护性，以确保系统的性能和稳定性。同时，我们关注用户体验和界面设计，使得系统易于使用和美观。我们的目标是为企业提供高质量的业务管理服务，以提高其效率和业务水平。",
    ],
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/Service2.png",
    title: "小程序开发",
    content: [
      "我们的团队拥有丰富的小程序开发经验，并可为贵公司提供专业的小程序开发服务。我们具有开发各种类型小程序的能力，包括商城，社交，教育等。我们注重用户体验和界面设计，能够提供美观，易于使用的小程序。同时，我们也注重代码的质量和可维护性，确保小程序的性能和稳定性。我们的目标是为贵公司提供最好的小程序开发服务，以实现贵公司的业务目标和成功。",
    ],
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/Service3.png",
    title: "IT咨询",
    content: [
      "我们是一家专业的IT咨询服务提供商，拥有丰富的IT行业经验和专业技能。我们致力于为客户提供全面的IT咨询服务，包括信息安全、数据管理、云计算、人工智能、软件开发等领域。我们注重理解客户需求，提供个性化解决方案。我们拥有一支高素质的团队，掌握最新技术和趋势，为客户提供最佳服务和支持。我们的目标是为客户创造最大价值!",
    ],
  },
  {
    img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/Service4.png",
    title: "等等...",
    content: [
      "我们会提供全方位的定制化IT解决方案。我们拥有经验丰富的团队成员，掌握最新的技术和趋势。我们注重与客户深入沟通，理解客户的业务需求和挑战，提供量身定制的服务。我们致力于帮助客户降低成本、提高效率、增强核心竞争力，为客户创造更多的价值。我们的价值在于，将客户的成功作为我们的目标，并为此不断努力。",
    ],
  },
];

// 宗旨
const manages = [
  {
    img: [
      "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/Purpose.jpg",
    ],
  },
];
// 核心优势
const spec = {
  product: [
    {
      img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/advantage1.png",
      title: "云存储方案",
      contents: ["自带云存储方案", "支持阿里云OSS等"],
    },
    {
      img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/advantage2.png",
      title: "系统集成",
      contents: [
        "专注于系统集成服务",
        "具备各类系统之间数据共享和高效连接的能力",
      ],
    },
    {
      img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/advantage3.png",
      title: "前端不限语言",
      contents: ["按需使用语言框架", "VUE2、VUE3、UNIAPP、JS、TS等随意使用"],
    },
    {
      img: "https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/advantage4.png",
      title: "开发能力",
      contents: ["可打造优秀的品牌营销网站", "帮助客户实现品牌价值最大化"],
    },
  ],
};

/* 跳转到经典案例详情部分 */
const ToclassCaseBox = (item) => {
  router.push({
    name: "CaseDetail",
    query: {
      row: item,
    },
  });
};
// 经典案例移入到title

const imgEnter = () => {
  const ImgclassRight = document.querySelector(".classRight");
  const myDiv = document.querySelector("#myDiv");
  myDiv.style.transition = "all 0.5s linear";
  myDiv.style.backgroundSize = "100%";
  myDiv.style.backgroundImage =
    "linear-gradient(180deg, transparent 56%, #007AFF 0)";
  ImgclassRight.style.transform = "scale(1.3, 1.2)";
  ImgclassRight.style.filter = " grayscale(0)";
  ImgclassRight.style.transition = "all 0.7s linear";
  ImgclassRight.style.height = "100%";
};
const imgLeave = () => {
  const ImgclassRight2 = document.querySelector(".classRight");
  const myDiv2 = document.querySelector("#myDiv");
  myDiv2.style.transition = "all 0.5s linear";
  myDiv2.style.backgroundSize = "0";
  ImgclassRight2.style.transform = "scale(1, 1)";
  ImgclassRight2.style.filter = " grayscale(97%)";
  ImgclassRight2.style.transition = "all 0.7s linear";
  ImgclassRight2.style.width = "100%";
  ImgclassRight2.style.height = "100%";
};

// 回到顶部
const srcollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // 添加平滑滚动效果
  });
}; //回到游览器顶部
// 立即预约
const MakeAnAppointmentNow = async () => {
  //  校验手机号正则
  if (
    param.value.mobile &&
    param.value.memberName &&
    param.value.consultContent
  ) {
    console.log(+param.value.mobile);
    if (
      /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
        +param.value.mobile
      )
    ) {
      try {
        await wxSoftwareConsult(param.value);
        ElMessage.success("预约成功");
        param.value = {
          mobile: "",
          memberName: "",
          consultContent: "",
        };
      } catch (error) {
        ElMessage.warning("预约失败请微信联系");
      }
    } else {
      ElMessage.warning("请输入正确的手机号");
    }
  } else {
    ElMessage.warning("请填写完整信息");
  }
};
</script>

<style>
.swiper-pagination-bullet {
  width: 132px;
  height: 6px;
  display: inline-block;
  border-radius: 0;
  background: #fff;
  opacity: 0.4;
}
.myswiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
}
.swiper-slide {
  width: 300px; /*设为固定值*/
  width: auto; /*根据内容调整宽度*/
}

@media screen and (max-device-width: 915px) {
  .swiper-pagination-bullet {
    width: 10px;
    height: 3px;
    display: inline-block;
    border-radius: 0;
    background: #fff;
    opacity: 0.4;
  }
  .myswiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
  }
  .swiper-slide {
    width: 300px; /*设为固定值*/
    width: auto; /*根据内容调整宽度*/
  }
}
</style>
<style scoped>
/* 移动过去背景颜色改变 */
/* 左侧 */
.Isfff {
  display: none;
}
.classCaseBox {
  height: 29%;
  display: flex;
  align-items: center;
  background: #fff;
}
.classCaseBox:hover {
  display: flex;
  background: #35a0ee;
  color: #fff;
}
.classCaseBox:hover .Isblue {
  display: none;
}
.classCaseBox:hover .Isfff {
  display: block;
}

/* 右侧 */
.classRight {
  width: 100%;
  height: 100%;
  transition: all 0.7s linear;
  filter: grayscale(97%);
}
#myDiv {
  background-size: 0;
  background-repeat: no-repeat;
}

.brandImg {
  width: 100%;
  height: 100%;
}
.brandImg:hover {
  transform: scale(1.2, 1.2);
  transition: 0.5s;
}
.paginationBottom {
  bottom: 38px;
}
.PurposeTitle1 {
  position: absolute;
  top: 10%;
  margin: 0 auto;
  color: black;
  font-family: Novecentowide-DemiBold;
  font-size: 50px;
  text-align: center;
  width: 100%;
}
.PurposeTitle2 {
  position: absolute;
  top: 23%;
  color: black;
  font-family: Novecentowide-DemiBold;
  font-size: 60px;
  text-align: center;
  width: 100%;
}
.classTitle {
  font-size: 23px;
  width: 100%;
  height: 100%;
}
.img_Class {
  width: 605px;
  height: 505px;
}
.BlackBox {
  width: 605px;
  height: 505px;
  overflow: hidden;
  position: relative;
}

.callme2 {
  display: none;
}
.callFooter {
  background-image: url("https://img-scrm.oss-cn-hangzhou.aliyuncs.com/ZhuangDianWeb/static/other/bg_footer.jpg");
  height: 610px;
  text-align: center;
}
.mobile-block,
.sms-block {
  width: 623px;
  height: 105px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-left: 20px;
  margin-top: 49px;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.placehoder {
  color: #cccccc;
}
.mobile-icon {
  background: url("@/assets/userPhone.png");
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 10px;
}

.code-icon {
  background: url("@/assets/userName.png");
  width: 30px;
  height: 30px;
  background-size: cover;
  margin-right: 10px;
}

.mobile-input,
.code-input {
  color: #808080;
  font-size: 24px;
  font-family: SourceHanSansCN-Light;
}
.code-input {
  width: 300px;
}
.Customizedscheme {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}
.toCallBottom2 {
}
.mobile-block2 {
  margin-left: 35px;
}
.oneImg2 {
  display: none;
}
.toCallBottom2 {
  display: none;
}
@media screen and (max-device-width: 768px) {
  /* 一站式服务 */
  .border_parent {
    flex-direction: column;
    align-items: center;
  }
  .border {
    width: 350px;
    height: 200px;
  }
  /* 宗旨 */
  .PurposeTitle1 {
    top: -5%;
    font-size: 20px;
  }
  .PurposeTitle2 {
    top: 18%;
    font-size: 23px;
  }
  .product_spec_parent {
    flex-direction: column;
  }
  /* 经典案例 */
  .classTitle {
    font-size: 16px;
    padding: 0px;
  }
  .classBox {
    flex-direction: column;
  }
  .img_Class {
    width: 96vw;
    height: 22rem;
  }
  .BlackBox {
    width: 96vw;
    height: 22rem;
  }
  .callme {
    display: none;
  }
  .callme2 {
    display: block;
    padding-top: 70px;
  }
  .callFooter {
    height: 288px;
  }
  .Customizedscheme {
    flex-direction: column;
  }
  .mobile-block {
    width: 100%;
  }
  .toCallBottom2 {
  }

  .mobile-block2 {
    margin-left: 0;
  }
  .MobileImg {
    width: 100%;
    height: 200px;
  }
  .paginationBottom {
    bottom: 8px;
  }
  .oneImg2 {
    display: block;
  }
  .oneImg {
    display: none;
  }
  .toCallBottom1 {
    display: none;
  }
  .toCallBottom2 {
    display: block;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* @import "swiper/css/swiper.css"; */
/* 轮播样式 */

.carousel ::v-deep(.el-carousel__indicator) button {
  display: inline-block;
  width: 90px;
  height: 5px;
  margin-bottom: 30px;
  /* border-radius: 50%; */
  /* background-color: hotpink; */
}

/* 经典案例css */
/* .img_Class {
  width: 605px;
  height: 505px;
} */

/* .img_Class:hover {
  transform: scale(1.1, 1.1);
  transition: 0.7s;
} */

/* 经典案例css  */
.tabs_a {
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  margin-right: 2%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  color: #555;
  font-size: 18px;
  text-align: center;
  background: #f2f2f2;
  text-decoration: none;
}
.tabs_a :hover {
  color: #fff;
  background: #007bff;
  border-radius: 5px;
  transition: all 0.8s;
}
.active_tabs_a {
  color: #fff;
  background: #007bff;
  border-radius: 5px;
}
/* 经典案例移动之后得box */
.backGroundBox {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  line-height: 30px;
  display: none;
}

/* 更多资讯 */
.for-MOre {
  margin-top: 20px;
  width: 220px;
  height: 59px;
  line-height: 59px;
  font-size: 22px;
  color: #a5a5a5;
  margin: 0 auto;
  cursor: pointer;
  border: 1px solid #a5a5a5;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s all;
}

.grey {
  vertical-align: middle;
  width: 25px;
  margin-right: 10px;
}

/* 轮播第一个图片css */
.oneImg {
  position: absolute;
  top: 50%;
  margin-top: -214px;
  left: 50%;
  margin-left: -426px;
  color: #fff;
  font-family: Novecentowide-DemiBold;
}
.oneImg2 {
  position: absolute;
  top: 50%;
  margin-top: -47px;
  left: 50%;
  margin-left: -98px;
  color: #fff;
  font-family: Novecentowide-DemiBold;
}

/* 一站式服务 */
.border_parent {
  width: 70%;
  margin: 0 auto;
}
.title {
  padding: 40px 0 10px;
  background: #f8f8f8;
  text-align: center;
  position: relative;
}
.title h2:after {
  position: absolute;
  left: 50%;
  bottom: 20px;
  display: block;
  content: "";
  width: 60px;
  height: 3px;
  margin-left: -30px;
  background: #007bff;
}

.callme {
  padding-top: 200px;
}
.slider_img {
  height: 888px;
  width: 100%;
}

.solve {
  margin-top: 30px;
  margin-bottom: 10px;
}

.border {
  border-radius: 6px;
  border: 1px solid #ebeef5;
  height: auto;
  height: 485px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.border_one {
  margin-left: 0;
}

.border_img_parent {
  height: 208px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.product_mobile_content {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product_mobile_img {
  width: 50%;
}

.product_mobile_title {
  font-weight: bold;
  text-align: center;
}

.product_pc_one {
  text-align: center;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.font_size_30 {
  font-size: 1.875em;
}

.product_font_style {
  font-size: 1.125em;
}

.product_pc_img {
  width: 100%;
}

.product_pc_img_div {
  width: 50%;
  float: left;
  display: flex;
  align-items: center;
}

.product_pc {
  display: flex;
  width: 90%;
  margin: 20px auto;
  background: #f2f6fc;
  align-items: center;
}

.product_spec_title {
  margin-top: 30px;
  margin-bottom: 10px;
}

.product_spec_parent {
  display: flex;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto;
  justify-content: center;
}

.cooperation_parent {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 20px auto;
  align-items: center;
  height: auto;
}
.about_me_p {
  font-size: 1.25em;
  font-weight: normal;
  padding: 0 20px;
  margin: 10px;
}

.border_title {
  font-family: "PingFang SC";
  margin-top: 10px;
  font-size: 1.125em;
  font-weight: bold;
  text-align: center;
}
.border_content_one {
  margin-top: 20px;
  height: 155px;
  line-height: 21px;
  padding: 0 16px 0 22px;
  font-size: 12px;
  text-indent: 1rem;
}
.border_content_two {
  margin-top: 5px;
  font-size: 1.125em;
  margin-bottom: 25px;
  text-align: center;
}

.img_border {
  width: 278px;
  height: 208px;
}
.img_border:hover {
  transform: scale(1.2, 1.2);
  transition: 1.2s;
}

.border_spec_inner_one {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  height: 70px;
}

.border_spec_content_one {
  margin-top: 15px;
  font-size: 14px;
  float: left;
  text-align: center;
  color: #7c7c7c;
}

.border_spec_inner_last {
  margin-top: 15px;
  font-size: 14px;
  float: left;
  margin-bottom: 25px;
  text-align: center;
  color: #7c7c7c;
}

.cooperation {
  margin-top: 30px;
  width: 100%;
}

.about_me {
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
}

.product_mobile {
  width: 90%;
  align-items: center;
  margin: 20px auto;
  background: #f2f6fc;
}

.product_mobile_img_div {
  width: 50%;
  height: auto;
}

.product_mobile_content_one {
  margin-top: 20px;
  text-align: center;
}

.product_mobile_content_two {
  margin-top: 10px;
  text-align: center;
}
.border_spec {
  border-radius: 6px;
  float: left;
  width: 320px;
  height: 280px;
}
.border_spec:hover {
  background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
}

.border_spec_img {
  width: 75px;
  height: 55px;
  align-self: center;
}

.font_size_24 {
  margin-top: 10px;
  font-size: 22px;
}
/* ok */
</style>
